import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  inject,
  input,
} from '@angular/core';
import { IconRegistry } from '@lib/icon-registry';

@Component({
  standalone: true,
  selector: 'dsp-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  public $icon = input<string>('info', { alias: 'icon' });
  public $html = input<string>(null, { alias: 'html' });

  private el = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);

  constructor() {
    effect(() => {
      if (this.$icon()) {
        this.el.nativeElement.style.setProperty('--icon', IconRegistry.getIcon(this.$icon()));
        this.cd.markForCheck();
      }
    });
  }
}
